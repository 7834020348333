import React from 'react';
import styled from 'styled-components';

import { Button, SEO } from 'components/UI';
import analytics from 'data/analytics.json';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	DESKTOP_GRID_SIZE,
	TABLET_GRID_SIZE
} from 'consts';
import { MediaQueries, Sizes } from 'environment';

export default function NotFoundPage() {
	return (
		<Container>
			<SEO title="404 - Page not found" />
			<Content>
				<Image src="/images/404.svg" alt="Not found icon" />
				<Title>404 Page not found</Title>
				<Description>
					It seems like the search wire got interrupted somewhere. While we are trying to
					fix it we suggest to relax and enjoy our other pages.
				</Description>
				<Button name={analytics.nonExistingPage} to="/" width={30}>
					Back to homepage
				</Button>
			</Content>
		</Container>
	);
}

const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 4rem;
	margin-bottom: 12rem;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
		margin-bottom: 6rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

const Image = styled.img`
	@media ${MediaQueries.phone} {
		width: calc(100vw - 4rem);
	}
`;

const Title = styled.h1`
	margin-top: 4rem;
	margin-bottom: 2rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}
`;

const Description = styled.p`
	margin-bottom: 4rem;
	max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	text-align: center;
`;
